.back-office-panel {
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #ddd;
        border-radius: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px #ddd;
    }
}

.website {
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #ddd;
        border-radius: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px #ddd;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #ddd;
    border-radius: 7px;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    -webkit-box-shadow: inset 0 0 6px #ddd;
}
