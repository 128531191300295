.i-alerts-container {
    --ialert-success: #2459cc;
    --ialert-error: #d51c28;
    --ialert-warning: rgb(255, 244, 142);
    --ialert-info: #138dff;

    position: fixed;
    top: 80px;
    right: 20px;
    z-index: 99999;


    .i-alert {
        display: block;
        min-width: 200px;
        max-width: 410px;
        min-height: 50px;
        margin-bottom: 10px;
        border-radius: 0px;
        z-index: 10000000 !important;
        color: #fff;
        cursor: default;
        margin-top: 20px;
        // box-shadow:0 3px 4px #ccc;

        p {
            font-weight: normal;
            margin: 0px;
        }

        .title {
            padding: 4px 15px;
            font-weight: bold;
            font-size: 13px;
            margin: 0px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .message {
            border-top: 1px solid #fff;
            padding: 2px 15px;
            font-size: 13px;
        }

        .cross {
            width: 30px;
            height: 30px;
            border-radius: 2px 2px 0px 0px;
            background-color: #fff;
            border: 3px solid;
            margin-top: -15px;
            text-align: center;
            font-weight: bold;
            font-size: 13px;
            display: flex;
            justify-content: center;
            align-items: center;

            a, a:hover {
                color: #333;
                text-decoration: none;
                font-size: 28px;
                font-weight: normal;
            }
        }
    }
}


.i-alert.success {
    background: var(--ialert-success);
    .title, .message {
        color: #fff;
    }
    .cross{
        border-color: var(--ialert-success);
    }
}

.i-alert.error {
    background: var(--ialert-error);
    .title, .message {
        color: #fff;
    }
    .cross{
        border-color: var(--ialert-error);
    }
}

.i-alert.warning {
    background: var(--ialert-warning);
    .title, .message {
        color: #333;
        border-top-color: #333;
    }
    .cross{
        border-color: var(--ialert-warning);
    }
}

.i-alert.info {
    background: var(--ialert-info);
    .title, .message {
        color: #fff;
    }
    .cross{
        border-color: var(--ialert-info);
    }
}
