.back-office-panel {

    button.rg {
        outline: none !important;
        border: none;
        font-size: 13px;
        font-weight: 300;
        color: var(--ap-btn-fg);
        border-radius: 4px;
        padding: 5px 10px;

        &.disabled {
            opacity: 0.7;
        }

        &.lg {
            padding: 8px 15px;
            font-size: 16px;
        }

        &.sm {
            padding: 3px 6px;
            font-size: 12px;
        }

        &.xs {
            padding: 2px 5px;
            font-size: 10px;
        }

        @each $style in $styles {
            &.#{$style} {
                background: var(--ap-#{$style});
            }
        }
    }

    button.rg:hover {
        opacity: 0.9;
    }

    /* ---------------------------------------------
     * Button Groups
     * --------------------------------------------- */
    .rg-button-group {
        display: inline-flex;
        position: relative;
        width: max-content;

        .icon {
            display: flex;
            align-items: center;
            width: 27px;
            border-radius: 4px;
            justify-content: center;
            color: #fff;
            opacity: 0.8;

            @each $style in $styles {
                &.#{$style} {
                    background: var(--ap-#{$style});
                }
            }
        }

        .pre {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .post {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .pre + button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
            margin-left: 0 !important;
        }

        button.post {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            margin-right: 0 !important;
        }

        /* * * * * * * * * * * * * * * * * *
        * Button Dropdown
        * * * * * * * * * * * * * * * * * */
        label {
            display: flex;
            margin-bottom: 0;

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked ~ .button-menu {
                display: block;
            }
        }

        button ~ label .button-menu {
            top: 40px;
        }

        button.lg ~ label .button-menu {
            top: 50px;
        }

        button.sm ~ label .button-menu {
            top: 35px;
        }

        button.xs ~ label .button-menu {
            top: 30px;
        }
    }

    .button-menu {
        position: absolute;
        width: max-content;
        display: none;
        left: 0;
        z-index: 100;
        ul {
            background-color: #fff;
            border-radius: 4px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px 20px;
                color: #fff;
                border-bottom: 1px solid #fff;
            }
        }

    }

    /* Menu top arrow */
    .button-menu::before {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        transform: rotate(-45deg);
        position: absolute;
        top: -5px;
        left: 10px;
        z-index: -1;
    }

    .button-menu.align-right {
        right: 0;
        left: unset;
    }

    .button-menu.align-right::before {
        left: calc(100% - 22px);
    }

    /* Set Colors */
    @each $style in $styles {
        .button-menu.#{$style} ul {
            border: 1px solid var(--ap-#{$style});
            border-bottom: none;
        }

        .button-menu.#{$style} ul li {
            border-bottom: 1px solid var(--ap-#{$style});
            color: black;
        }
        .button-menu.#{$style} ul li:hover {
            background: var(--ap-#{$style});
            color: #fff;
            cursor: pointer;
        }

        .button-menu.#{$style}::before {
            background: var(--ap-#{$style});
        }
    }

    /* * * * * * * * * * * * * * * * * *
     * Loading Buttons
     * * * * * * * * * * * * * * * * * */
    .in-progress {
        display: inline-flex;
        align-items: center;
    }

    button.in-progress .loading-dots span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        border-radius: 50%;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        margin: 0 3px;
    }

    button.lg.in-progress .loading-dots span {
        width: 9px;
        height: 9px;
    }

    button.sm.in-progress .loading-dots span {
        width: 6px;
        height: 6px;
    }

    button.xs.in-progress .loading-dots span {
        width: 5px;
        height: 5px;
    }

    button .icon {
        padding: 10px 15px 10px 15px;
        border-left: 1.5px solid #fff !important;
    }

    .in-progress .loading-dots span:nth-child(2) {
        animation-delay: 0.2s;
    }

    .in-progress span:nth-child(3) {
        animation-delay: 0.4s;
    }
    @keyframes blink {
        0% {
            opacity: 0.2;
        }

        20% {
            opacity: 1;
        }

        100% {
            opacity: 0.2;
        }
    }
}


.website {

    button.rg {
        outline: none !important;
        border: none;
        font-size: 13px;
        font-weight: 300;
        color: var(--ap-btn-fg);
        border-radius: 4px;
        padding: 5px 10px;

        &.lg {
            padding: 8px 15px;
            font-size: 16px;
        }

        &.sm {
            padding: 3px 6px;
            font-size: 12px;
        }

        &.xs {
            padding: 2px 5px;
            font-size: 10px;
        }

        @each $style in $styles {
            &.#{$style} {
                background: var(--ap-#{$style});
            }
        }
    }

    button.rg:hover {
        opacity: 0.9;
    }

    /* ---------------------------------------------
     * Button Groups
     * --------------------------------------------- */
    .rg-button-group {
        display: flex;
        position: relative;

        .icon {
            display: flex;
            align-items: center;
            width: 27px;
            border-radius: 4px;
            justify-content: center;
            color: #fff;
            opacity: 0.8;

            @each $style in $styles {
                &.#{$style} {
                    background: var(--ap-#{$style});
                }
            }
        }

        .pre {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .post {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .pre + button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
            margin-left: 0 !important;
        }

        button.post {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            margin-right: 0 !important;
        }

        /* * * * * * * * * * * * * * * * * *
        * Button Dropdown
        * * * * * * * * * * * * * * * * * */
        label {
            display: flex;
            margin-bottom: 0;

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked ~ .button-menu {
                display: block;
            }
        }

        button ~ label .button-menu {
            top: 40px;
        }

        button.lg ~ label .button-menu {
            top: 50px;
        }

        button.sm ~ label .button-menu {
            top: 35px;
        }

        button.xs ~ label .button-menu {
            top: 30px;
        }
    }

    .button-menu {
        position: absolute;
        width: max-content;
        display: none;
        left: 0;
        z-index: 100;
        ul {
            background-color: #fff;
            border-radius: 4px;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px 20px;
                color: #fff;
                border-bottom: 1px solid #fff;
            }
        }

    }

    /* Menu top arrow */
    .button-menu::before {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        transform: rotate(-45deg);
        position: absolute;
        top: -5px;
        left: 10px;
        z-index: -1;
    }

    .button-menu.align-right {
        right: 0;
        left: unset;
    }

    .button-menu.align-right::before {
        left: calc(100% - 22px);
    }

    /* Set Colors */
    @each $style in $styles {
        .button-menu.#{$style} ul {
            border: 1px solid var(--ap-#{$style});
            border-bottom: none;
        }

        .button-menu.#{$style} ul li {
            border-bottom: 1px solid var(--ap-#{$style});
            color: black;
        }
        .button-menu.#{$style} ul li:hover {
            background: var(--ap-#{$style});
            color: #fff;
            cursor: pointer;
        }

        .button-menu.#{$style}::before {
            background: var(--ap-#{$style});
        }
    }

    /* * * * * * * * * * * * * * * * * *
     * Loading Buttons
     * * * * * * * * * * * * * * * * * */
    .in-progress {
        display: inline-flex;
        align-items: center;
    }

    button.in-progress .loading-dots span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        border-radius: 50%;
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        margin: 0 3px;
    }

    button.lg.in-progress .loading-dots span {
        width: 9px;
        height: 9px;
    }

    button.sm.in-progress .loading-dots span {
        width: 6px;
        height: 6px;
    }

    button.xs.in-progress .loading-dots span {
        width: 5px;
        height: 5px;
    }

    button .icon {
        padding: 10px 15px 10px 15px;
        border-left: 1.5px solid #fff !important;
    }

    .in-progress .loading-dots span:nth-child(2) {
        animation-delay: 0.2s;
    }

    .in-progress span:nth-child(3) {
        animation-delay: 0.4s;
    }
    @keyframes blink {
        0% {
            opacity: 0.2;
        }

        20% {
            opacity: 1;
        }

        100% {
            opacity: 0.2;
        }
    }
}
