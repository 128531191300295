.back-office-panel {

    .rg-checkbox-container {
        display: flex;

        /* Customize the label (the container) */
        .checkbox {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 0;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 30px;
        }

        /* Hide the browser's default checkbox */
        .checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkbox .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 1px solid var(--ap-active-text);
            border-radius: 3px;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkbox .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .checkbox input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .checkbox .checkmark:after {
            left: 8px;
            top: 2px;
            width: 7px;
            height: 15px;
            border: solid var(--ap-active-text);
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &.text-bottom,
        &.text-top {
            flex-direction: column;
            align-items: center;
        }

        span.text {
            font-size: 16px;
            color: var(--ap-active-text);
        }
    }

    /**
     * Styles
     */
    @each $style in $styles {
        .rg-checkbox-container.#{$style} .checkbox .checkmark:after {
            border-color: var(--ap-#{$style});
        }

        .rg-checkbox-container.#{$style} .checkbox:hover .checkmark:after {
            border-color: #fff;
        }

        .rg-checkbox-container.#{$style} .checkbox:hover input ~ .checkmark {
            background-color: var(--ap-#{$style});
        }
    }

    /**
     * Sizes
     */
     .rg-checkbox-container.sm {

        .checkbox .checkmark {
            width: 20px;
            height: 20px;
        }

        .checkbox .checkmark:after {
            left: 6px;
            width: 6px;
            height: 13px;
            border-width: 0 3px 3px 0;
            top: 1px;
        }

        span.text {
            font-size: 14px;
        }

        .checkbox {
            padding-left: 30px;
            height: 21px;
        }
    }

    /* Extra Small Size */
    .rg-checkbox-container.xs {

        .checkbox .checkmark {
            width: 15px;
            height: 15px;
            border-radius: 3px;
        }

        .checkbox .checkmark:after {
            left: 4px;
            width: 5px;
            height: 9px;
            border-width: 0 2px 2px 0;
            top: 1px;
        }

        span.text {
            font-size: 11px;
        }

        .checkbox {
            padding-left: 22px;
            height: 15px;
        }
    }
}
