.back-office-panel {
    .radio-container {
        margin-right: 35px;
        .text {
            color: var(--ap-active-text);
            font-size: 13px;
        }

        label.radio {
            cursor: pointer;
            width: auto !important;
            padding-left: 25px !important;
            display: block;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            /* Hide the browser's default radio button */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
    }

    /* Create a custom radio button */
    .radio .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .radio:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .radio .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio .checkmark:after {
        top: 5px;
        left: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: white;
    }

    .radio-group {
        display: flex;
        flex-wrap: wrap;
    }

    .radio-group.horizontal {
        width: 100%;
        align-items: center;
    }

    .radio-group.vertical {
        width: 100%;
        flex-direction: column;
    }

    .radio-container {
        display: flex;
        margin-bottom: 10px;
    }

    .radio.primary input:checked ~ .checkmark {
        background-color: var(--ap-primary);
    }
    .radio.secondary input:checked ~ .checkmark {
        background-color: var(--ap-secondary);
    }
    .radio.success input:checked ~ .checkmark {
        background-color: var(--ap-success);
    }
    .radio.danger input:checked ~ .checkmark {
        background-color: var(--ap-danger);
    }
    .radio.info input:checked ~ .checkmark {
        background-color: var(--ap-info);
    }
    .radio.warning input:checked ~ .checkmark {
        background-color: var(--ap-warning);
    }
    .radio.dark input:checked ~ .checkmark {
        background-color: var(--ap-dark);
    }
    .radio.light input:checked ~ .checkmark {
        background-color: var(--ap-light);
    }

    /* Size Variants */
    .radio-container.sm {
        margin-bottom: 3px;

        .radio {
            padding-left: 18px;
            margin-bottom: 0;

            .checkmark {
                height: 12px;
                width: 12px;
            }

            .checkmark:after {
                top: 4px;
                left: 4px;
                width: 4px;
                height: 4px;
            }
        }

        span.text {
            font-size: 11px;
            margin-top: 1px;
            /* position: relative; */
        }
    }

    .radio-container.lg {
        margin-bottom: 3px;

        .radio {
            padding-left: 18px;
            margin-bottom: 0;

            .checkmark {
                height: 20px;
                width: 20px;
            }

            .checkmark:after {
                top: 6px;
                left: 6px;
                width: 8px;
                height: 8px;
            }
        }

        span.text {
            font-size: 15px;
            margin-top: 1px;
            margin-left: 4px;
            /* position: relative; */
        }
    }
}
