.back-office-panel {
    select.rg, input.rg {
        height: 31px;
    }

    select.rg,
    input.rg,
    textarea.rg {
        width: 100%;
        border: 1px solid #e0e0db;
        font-weight: 300;
        border-radius: 4px;
        padding: 2px 6px;
        font-size: 12px;
        margin: 0;
    }

    textarea.rg {
        // height: auto !important;
        font-weight: 300;
    }

    input.rg:focus,
    select.rg:focus,
    textarea.rg:focus {
        border: 1.8px solid var(--ap-secondary);
        box-shadow: none;
        outline: none;
    }

    .rg-input-group {
        margin-bottom: 5px;

        label {
            font-size: 12px;
            display: block;
            width: 100%;
            color: var(--ap-inactive-text);
            font-weight: 300;
            margin: 0;
            padding: 0;
        }

        .group {
            display: flex;
            width: 100%;

            .pre {
                & + input,
                & + select {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-left: none;
                }

                button {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    height: 31px;
                }
            }

            .post {
                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 31px;
                }
            }

            input.post, select.post {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: none;
            }
        }
    }

    .actions {
        flex-grow: 2;
        display: flex;
        justify-content: flex-end;
    }

    /* Input Errors */
    .ng-invalid.ng-touched,
    .ng-invalid.ng-dirty,
    .ng-invalid.form-submitted {
        border-color: lightcoral !important;
    }

    .errors p {
        margin: 0;
        font-size: 11px;
        color: var(--ap-danger);
        font-weight: 300;
    }

    select.per-page {
        width: 80px;
    }
}

.website {
}
