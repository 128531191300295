.back-office-panel {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        padding-right: 5px;
        -webkit-overflow-scrolling: touch;
    }

    table.rg {
        $tableBackgroundColor: var(--ap-table-bg);
        $alternate-row-opacity: 0.1;
        color: var(--ap-table-text);
        margin: 0 !important;
        width: 100%;
        // background-color: #{$tableBackgroundColor};
        border-radius: 10px 10px 10px 10px !important;

        @each $style in $styles {
            &.#{$style}.alternate-rows tr:nth-child(even) {
                background-color: var(--ap-alternate-row-color);
            }

            &.#{$style}.alternate-cols td:nth-child(even) {
                background-color: var(--ap-alternate-row-color);
            }

            &.#{$style} thead th {
                color: var(--ap-#{$style});
                font-weight: 600;
                font-size: 12px;
                padding: 10px 10px;
                border: 1px solid var(--ap-inactive-text);
                box-shadow: inset 0px -3px 0px 0px var(--ap-#{$style});
                background-color: var(--ap-table-bg);

                &.xs {
                    box-shadow: inset 0px -2px 0px 0px var(--ap-#{$style});
                    font-weight: 400;
                    font-size: 12px;
                    padding: 4px 6px;
                }
            }

            &.#{$style} tfoot th {
                color: var(--ap-#{$style});
                font-weight: 600;
                font-size: 13px;
                padding: 6px 6px;
                border-top: 3px solid var(--ap-#{$style});
                background-color: var(--ap-table-bg);

                &.xs {
                    box-shadow: inset 0px -2px 0px 0px var(--ap-#{$style});
                    font-weight: 400;
                    font-size: 12px;
                    padding: 4px 6px;
                }
            }
        }

        thead {
            &.sticky {
                position: sticky;
                top: 0;
                z-index: 99;
                // background-color: #{$tableBackgroundColor};
            }

            th {
                &.center {
                    text-align: center;
                }
                &.right {
                    text-align: right;
                }
            }
        }

        tfoot {
            th {
                &.center {
                    text-align: center;
                }
                &.right {
                    text-align: right;
                }
            }
        }
        tbody {
            td {
                font-size: 12px;
                padding: 5px 10px;
                border: 1px solid var(--ap-inactive-text);

                // border-bottom: none;
                &.center {
                    text-align: center;
                }
                &.right {
                    text-align: right;
                }

                &.xs {
                    font-size: 11px;
                    padding: 5px;
                }
            }

            td:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            td:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
}
