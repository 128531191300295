.page-wrapper.toggled .sidebar {
    left: -220px;
}

.page-wrapper.toggled .sidebar .sidebar-slider i {
    margin-left: 10px;
}

.sidebar .ps.ps--scrolling-y > .ps__rail-y,
.sidebar > .ps.ps--scrolling-x > .ps__rail-x,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
}
.sidebar .ps.ps--active-y > .ps__rail-y:hover,
.sidebar > .ps.ps--active-y > .ps__rail-y.ps--clicking {
    width: 10px !important;
}
ol,
ul {
    list-style: none;
}

.ng-option.ng-option-selected,
.ng-option.ng-option-selected.ng-option-marked {
    background-color: #002475d9 !important;
    color: #fff !important;
}
.ng-option.ng-option-marked {
    background-color: #00247526 !important;
    color: #333;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 2px solid #002475 !important;
    box-shadow: none;
}

/* Calendar */
.bs-datepicker-head,
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after {
    background-color: #002375d9 !important;
}
.bs-datepicker-body table td.week span {
    color: #002475d9 !important;
    font-weight: 600;
}
