@import 'assets/styles/bootstrap-custom.scss';
@import 'assets/styles/root.scss';
@import 'assets/styles/fonts.css';
@import 'assets/styles/sidebar.scss';
@import 'assets/styles/website.scss';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/input.scss';
@import 'assets/styles/select.scss';
@import 'assets/styles/checkbox.scss';
@import 'assets/styles/radio-button.scss';
@import 'assets/styles/badges.scss';
@import 'assets/styles/ialerts.scss';
@import 'assets/styles/on-off-toggle.scss';
@import 'assets/styles/typography.scss';
@import 'assets/styles/table.scss';
@import 'assets/styles/scrollbar.scss';
@import 'assets/styles/containers.scss';
@import 'assets/styles/data-lists.scss';
@import 'assets/styles/back-office-panel.scss';
@import 'assets/styles/thirdparty-customizations/bootstrap.scss';
@import 'assets/styles/thirdparty-customizations/autocomplete.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
.pac-container {
    z-index: 1056 !important;
}

.mandatory {
    color: red;
    display: inline-block;
    line-height: 7px;
    vertical-align: text-bottom;
    margin-left: 2px;
    font-weight: bold;
    font-size: 13px;
}
.required-field::after {
    content: '*';
    color: red;
    font-size: 20px;
    margin-left: 2px; /* Optional: Add margin for spacing */
}
