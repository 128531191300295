/**
 * Modal Customization
 */

.bordered .modal-content {
    box-shadow: 0 0 8px 2px #000;
}
.modal-content {
    background-color: var(--ap-modal-surface);
    border-radius: 3px;
    border: none;

    .modal-body {

        .title {
            font-size: 16px;
            font-weight: bold;
            border-bottom: 1px dashed;
            padding-bottom: 5px;
        }

        p {
            font-size: 13px;
            color: var(--ap-active-text);
        }
    }
}

.component-modal {
    .modal-content {
        padding: 15px;
    }
}
