.back-office-panel {
    /* CHECKBOX START */
    .personalize-wrapper {
        width: 80%;
    }

    .rg-switch [type="checkbox"]:not(:checked),
    .rg-switch [type="checkbox"]:checked {
        position: unset;
    }
    label.rg-switch {
        position: relative;
        display: inline-block;
        width: 40px !important;
        height: 18px;
    }
    .rg-switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    @each $style in $styles {
        .rg-switch.#{$style} input:checked + .slider {
            background-color: var(--ap-#{$style});
        }

        .rg-switch.#{$style} input:focus + .slider {
            box-shadow: 0 0 1px var(--ap-#{$style});
        }
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
    /* CHECKBOX END */
}
